import { FaFacebookF, FaLinkedinIn, FaMediumM, FaTwitter } from "react-icons/fa";
export const underlineAnimation = {
  "::after": {
    "content": "var(--tw-content)",
    "position": "absolute",
    "bottom": "0px",
    "left": "0px",
    "height": "0.125rem",
    "width": "100%",
    "transformOrigin": "bottom right",
    "--tw-scale-x": "0",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))",
    "--tw-bg-opacity": "1",
    "backgroundColor": "rgb(255 255 255 / var(--tw-bg-opacity))",
    "transitionProperty": "color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter",
    "transitionTimingFunction": "cubic-bezier(0.4, 0, 0.2, 1)",
    "transitionDuration": "300ms"
  },
  ":hover::after": {
    "content": "var(--tw-content)",
    "transformOrigin": "bottom left",
    "--tw-scale-x": "1",
    "transform": "translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))"
  }
};
export const pageLinks = ([{
  href: "#platform",
  label: "The Platform"
}, {
  href: "#useCases",
  label: "The Use Cases"
}, {
  href: "#about",
  label: "About Us"
}] as const);
export const privacyLinks = ([{
  href: "https://www.hayden.ai/privacy-policy",
  label: "Privacy Policy"
}, {
  href: "https://www.hayden.ai/terms-of-use",
  label: "Website Terms of Service"
}, {
  href: "https://www.hayden.ai/intellectual-property-copyright",
  label: "Legal"
}] as const);
export const socials = ([{
  url: `https://twitter.com/haydenaitech`,
  // TODO: change Twitter
  platform: FaTwitter,
  platformName: `Twitter`
}, {
  url: `https://www.linkedin.com/company/fireworxai/`,
  platform: FaLinkedinIn,
  platformName: `LinkedIn`
}, {
  url: `https://www.facebook.com/haydenaitech`,
  // TODO: change Facebook
  platform: FaFacebookF,
  platformName: `Facebook`
}, {
  url: `https://haydenai.medium.com/`,
  // TODO: change Medium
  platform: FaMediumM,
  platformName: `Medium`
}] as const);