function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { css as _css } from "@emotion/react";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var _ref = process.env.NODE_ENV === "production" ? {
  name: "wdyy9y",
  styles: "@keyframes dot{0%{opacity:0;}50%{opacity:1;}100%{opacity:0;}}"
} : {
  name: "36ircv-Ellipsis",
  styles: "@keyframes dot{0%{opacity:0;}50%{opacity:1;}100%{opacity:0;}};label:Ellipsis;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvd2ViL3NyYy9jb21wb25lbnRzL2VsbGlwc2lzLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFZYyIsImZpbGUiOiIvdmVyY2VsL3BhdGgwL3dlYi9zcmMvY29tcG9uZW50cy9lbGxpcHNpcy50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgdHcsIHsgY3NzIH0gZnJvbSBcInR3aW4ubWFjcm9cIjtcbi8vID09PT09PT09PSBDT01QT05FTlQgPT09PT09PT09XG5cbi8qKlxuICogQW4gZWxsaXBzaXMgdGhhdCBhbmltYXRlcyBlYWNoIG9mIGl0cyBkb3RzIG9wYWNpdHkgaW5maW5pdGVseSBpbiBhIHNlcXVlbmNlLlxuICpcbiAqIEBwYXJhbSBwcm9wc1xuICogQHJldHVybnMgQSBSZWFjdCBjb21wb25lbnQuXG4gKi9cbmNvbnN0IEVsbGlwc2lzID0gKCkgPT4ge1xuICByZXR1cm4gKFxuICAgIDxzcGFuXG4gICAgICBjc3M9e2Nzc2BcbiAgICAgICAgQGtleWZyYW1lcyBkb3Qge1xuICAgICAgICAgIDAlIHtcbiAgICAgICAgICAgIG9wYWNpdHk6IDA7XG4gICAgICAgICAgfVxuICAgICAgICAgIDUwJSB7XG4gICAgICAgICAgICBvcGFjaXR5OiAxO1xuICAgICAgICAgIH1cbiAgICAgICAgICAxMDAlIHtcbiAgICAgICAgICAgIG9wYWNpdHk6IDA7XG4gICAgICAgICAgfVxuICAgICAgICB9XG4gICAgICBgfVxuICAgID5cbiAgICAgIDxzcGFuIGNzcz17W3R3YG9wYWNpdHktMCBbYW5pbWF0aW9uOiBkb3QgMnMgaW5maW5pdGVdIFthbmltYXRpb24tZGVsYXk6IDBzXWBdfT7igKI8L3NwYW4+XG4gICAgICA8c3BhbiBjc3M9e1t0d2BvcGFjaXR5LTAgW2FuaW1hdGlvbjogZG90IDJzIGluZmluaXRlXSBbYW5pbWF0aW9uLWRlbGF5OiAwLjI1c11gXX0+4oCiPC9zcGFuPlxuICAgICAgPHNwYW4gY3NzPXtbdHdgb3BhY2l0eS0wIFthbmltYXRpb246IGRvdCAycyBpbmZpbml0ZV0gW2FuaW1hdGlvbi1kZWxheTogMC41c11gXX0+4oCiPC9zcGFuPlxuICAgIDwvc3Bhbj5cbiAgKTtcbn07XG5cbmV4cG9ydCBkZWZhdWx0IEVsbGlwc2lzO1xuIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "tgabf6",
  styles: "opacity:0;animation:dot 2s infinite;animation-delay:0s"
} : {
  name: "1iuq5bx-Ellipsis",
  styles: "opacity:0;animation:dot 2s infinite;animation-delay:0s;label:Ellipsis;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvd2ViL3NyYy9jb21wb25lbnRzL2VsbGlwc2lzLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUEwQlkiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC93ZWIvc3JjL2NvbXBvbmVudHMvZWxsaXBzaXMudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHR3LCB7IGNzcyB9IGZyb20gXCJ0d2luLm1hY3JvXCI7XG4vLyA9PT09PT09PT0gQ09NUE9ORU5UID09PT09PT09PVxuXG4vKipcbiAqIEFuIGVsbGlwc2lzIHRoYXQgYW5pbWF0ZXMgZWFjaCBvZiBpdHMgZG90cyBvcGFjaXR5IGluZmluaXRlbHkgaW4gYSBzZXF1ZW5jZS5cbiAqXG4gKiBAcGFyYW0gcHJvcHNcbiAqIEByZXR1cm5zIEEgUmVhY3QgY29tcG9uZW50LlxuICovXG5jb25zdCBFbGxpcHNpcyA9ICgpID0+IHtcbiAgcmV0dXJuIChcbiAgICA8c3BhblxuICAgICAgY3NzPXtjc3NgXG4gICAgICAgIEBrZXlmcmFtZXMgZG90IHtcbiAgICAgICAgICAwJSB7XG4gICAgICAgICAgICBvcGFjaXR5OiAwO1xuICAgICAgICAgIH1cbiAgICAgICAgICA1MCUge1xuICAgICAgICAgICAgb3BhY2l0eTogMTtcbiAgICAgICAgICB9XG4gICAgICAgICAgMTAwJSB7XG4gICAgICAgICAgICBvcGFjaXR5OiAwO1xuICAgICAgICAgIH1cbiAgICAgICAgfVxuICAgICAgYH1cbiAgICA+XG4gICAgICA8c3BhbiBjc3M9e1t0d2BvcGFjaXR5LTAgW2FuaW1hdGlvbjogZG90IDJzIGluZmluaXRlXSBbYW5pbWF0aW9uLWRlbGF5OiAwc11gXX0+4oCiPC9zcGFuPlxuICAgICAgPHNwYW4gY3NzPXtbdHdgb3BhY2l0eS0wIFthbmltYXRpb246IGRvdCAycyBpbmZpbml0ZV0gW2FuaW1hdGlvbi1kZWxheTogMC4yNXNdYF19PuKAojwvc3Bhbj5cbiAgICAgIDxzcGFuIGNzcz17W3R3YG9wYWNpdHktMCBbYW5pbWF0aW9uOiBkb3QgMnMgaW5maW5pdGVdIFthbmltYXRpb24tZGVsYXk6IDAuNXNdYF19PuKAojwvc3Bhbj5cbiAgICA8L3NwYW4+XG4gICk7XG59O1xuXG5leHBvcnQgZGVmYXVsdCBFbGxpcHNpcztcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "19tuap2",
  styles: "opacity:0;animation:dot 2s infinite;animation-delay:0.25s"
} : {
  name: "qfx73p-Ellipsis",
  styles: "opacity:0;animation:dot 2s infinite;animation-delay:0.25s;label:Ellipsis;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvd2ViL3NyYy9jb21wb25lbnRzL2VsbGlwc2lzLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUEyQlkiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC93ZWIvc3JjL2NvbXBvbmVudHMvZWxsaXBzaXMudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHR3LCB7IGNzcyB9IGZyb20gXCJ0d2luLm1hY3JvXCI7XG4vLyA9PT09PT09PT0gQ09NUE9ORU5UID09PT09PT09PVxuXG4vKipcbiAqIEFuIGVsbGlwc2lzIHRoYXQgYW5pbWF0ZXMgZWFjaCBvZiBpdHMgZG90cyBvcGFjaXR5IGluZmluaXRlbHkgaW4gYSBzZXF1ZW5jZS5cbiAqXG4gKiBAcGFyYW0gcHJvcHNcbiAqIEByZXR1cm5zIEEgUmVhY3QgY29tcG9uZW50LlxuICovXG5jb25zdCBFbGxpcHNpcyA9ICgpID0+IHtcbiAgcmV0dXJuIChcbiAgICA8c3BhblxuICAgICAgY3NzPXtjc3NgXG4gICAgICAgIEBrZXlmcmFtZXMgZG90IHtcbiAgICAgICAgICAwJSB7XG4gICAgICAgICAgICBvcGFjaXR5OiAwO1xuICAgICAgICAgIH1cbiAgICAgICAgICA1MCUge1xuICAgICAgICAgICAgb3BhY2l0eTogMTtcbiAgICAgICAgICB9XG4gICAgICAgICAgMTAwJSB7XG4gICAgICAgICAgICBvcGFjaXR5OiAwO1xuICAgICAgICAgIH1cbiAgICAgICAgfVxuICAgICAgYH1cbiAgICA+XG4gICAgICA8c3BhbiBjc3M9e1t0d2BvcGFjaXR5LTAgW2FuaW1hdGlvbjogZG90IDJzIGluZmluaXRlXSBbYW5pbWF0aW9uLWRlbGF5OiAwc11gXX0+4oCiPC9zcGFuPlxuICAgICAgPHNwYW4gY3NzPXtbdHdgb3BhY2l0eS0wIFthbmltYXRpb246IGRvdCAycyBpbmZpbml0ZV0gW2FuaW1hdGlvbi1kZWxheTogMC4yNXNdYF19PuKAojwvc3Bhbj5cbiAgICAgIDxzcGFuIGNzcz17W3R3YG9wYWNpdHktMCBbYW5pbWF0aW9uOiBkb3QgMnMgaW5maW5pdGVdIFthbmltYXRpb24tZGVsYXk6IDAuNXNdYF19PuKAojwvc3Bhbj5cbiAgICA8L3NwYW4+XG4gICk7XG59O1xuXG5leHBvcnQgZGVmYXVsdCBFbGxpcHNpcztcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1j5ghqj",
  styles: "opacity:0;animation:dot 2s infinite;animation-delay:0.5s"
} : {
  name: "18w3uk9-Ellipsis",
  styles: "opacity:0;animation:dot 2s infinite;animation-delay:0.5s;label:Ellipsis;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvd2ViL3NyYy9jb21wb25lbnRzL2VsbGlwc2lzLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUE0QlkiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC93ZWIvc3JjL2NvbXBvbmVudHMvZWxsaXBzaXMudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHR3LCB7IGNzcyB9IGZyb20gXCJ0d2luLm1hY3JvXCI7XG4vLyA9PT09PT09PT0gQ09NUE9ORU5UID09PT09PT09PVxuXG4vKipcbiAqIEFuIGVsbGlwc2lzIHRoYXQgYW5pbWF0ZXMgZWFjaCBvZiBpdHMgZG90cyBvcGFjaXR5IGluZmluaXRlbHkgaW4gYSBzZXF1ZW5jZS5cbiAqXG4gKiBAcGFyYW0gcHJvcHNcbiAqIEByZXR1cm5zIEEgUmVhY3QgY29tcG9uZW50LlxuICovXG5jb25zdCBFbGxpcHNpcyA9ICgpID0+IHtcbiAgcmV0dXJuIChcbiAgICA8c3BhblxuICAgICAgY3NzPXtjc3NgXG4gICAgICAgIEBrZXlmcmFtZXMgZG90IHtcbiAgICAgICAgICAwJSB7XG4gICAgICAgICAgICBvcGFjaXR5OiAwO1xuICAgICAgICAgIH1cbiAgICAgICAgICA1MCUge1xuICAgICAgICAgICAgb3BhY2l0eTogMTtcbiAgICAgICAgICB9XG4gICAgICAgICAgMTAwJSB7XG4gICAgICAgICAgICBvcGFjaXR5OiAwO1xuICAgICAgICAgIH1cbiAgICAgICAgfVxuICAgICAgYH1cbiAgICA+XG4gICAgICA8c3BhbiBjc3M9e1t0d2BvcGFjaXR5LTAgW2FuaW1hdGlvbjogZG90IDJzIGluZmluaXRlXSBbYW5pbWF0aW9uLWRlbGF5OiAwc11gXX0+4oCiPC9zcGFuPlxuICAgICAgPHNwYW4gY3NzPXtbdHdgb3BhY2l0eS0wIFthbmltYXRpb246IGRvdCAycyBpbmZpbml0ZV0gW2FuaW1hdGlvbi1kZWxheTogMC4yNXNdYF19PuKAojwvc3Bhbj5cbiAgICAgIDxzcGFuIGNzcz17W3R3YG9wYWNpdHktMCBbYW5pbWF0aW9uOiBkb3QgMnMgaW5maW5pdGVdIFthbmltYXRpb24tZGVsYXk6IDAuNXNdYF19PuKAojwvc3Bhbj5cbiAgICA8L3NwYW4+XG4gICk7XG59O1xuXG5leHBvcnQgZGVmYXVsdCBFbGxpcHNpcztcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
// ========= COMPONENT =========

/**
 * An ellipsis that animates each of its dots opacity infinitely in a sequence.
 *
 * @param props
 * @returns A React component.
 */
const Ellipsis = () => {
  return _jsxs("span", {
    css: _ref,
    children: [_jsx("span", {
      css: _ref2,
      children: "\u2022"
    }), _jsx("span", {
      css: _ref3,
      children: "\u2022"
    }), _jsx("span", {
      css: _ref4,
      children: "\u2022"
    })]
  });
};
export default Ellipsis;