import { createClient } from "next-sanity";
import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
export const projectId = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID;
export const dataset = process.env.NEXT_PUBLIC_SANITY_DATASET; // "production"
export const apiVersion = process.env.NEXT_PUBLIC_SANITY_API_VERSION; // "2023-01-01"

export const client = createClient({
  projectId,
  dataset,
  apiVersion,
  useCdn: true
});
const builder = imageUrlBuilder(client);
export function urlFor(source: SanityImageSource) {
  return builder.image(source);
}