function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { GetStaticPropsContext, InferGetStaticPropsType } from "next";
import Head from "next/head";
import Link from "next/link";
import type { SanityDocument } from "@sanity/client";
import groq from "groq";
import { PreviewSuspense } from "next-sanity/preview";
import dynamic from "next/dynamic";
import { client } from "@fireworx/utils/sanity.client";
import { SanityHomepage } from "@fireworx/types/sanity.types";
import Hero from "@fireworx/components/home/hero-section";
import AboutUs from "@fireworx/components/home/about-us-section";
import UseCaseSection from "@fireworx/components/home/use-case-section";
import Header from "@fireworx/components/header";
import Footer from "@fireworx/components/footer";
import { usePreview } from "@fireworx/utils/sanity.preview";
import Ellipsis from "@fireworx/components/ellipsis";
import PlatformSection from "@fireworx/components/home/platform-section";

// Dynamically import QuoteSection to save about 12kB of first load JS
// QuoteSection component JS size is large because of `react-spring-carousel` library
// We are able to lazy load it because it is "below the fold" and beyond the user's viewport on initial load
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
const QuoteSection = dynamic(() => import("@fireworx/components/home/quote-section"), {
  loading: () => _jsx("p", {
    children: "Loading..."
  })
});
const query = groq`*[_type == "homepage"]{
  ...,
  "platformButton": {
    ...platformButton,
    "link": select(
    platformButton.type == "file" => platformButton.file.asset->url,
    platformButton.type == "internal-link" => platformButton.internalLink,
    platformButton.type == "external-link" => platformButton.externalLink,
    platformButton.type == "scroll-to-link" => platformButton.scrollToLink,
    ""
  ),
  },
  "aboutUsButton": {
    ...aboutUsButton,
    "link": select(
    aboutUsButton.type == "file" => aboutUsButton.file.asset->url,
    aboutUsButton.type == "internal-link" => aboutUsButton.internalLink,
    aboutUsButton.type == "external-link" => aboutUsButton.externalLink,
    aboutUsButton.type == "scroll-to-link" => aboutUsButton.scrollToLink,
    ""
  ),
  },
  featuredUseCases[]->{
    title,
    "cardButton": {
    ...cardButton,
    "link": select(
    cardButton.type == "file" => cardButton.file.asset->url,
    cardButton.type == "internal-link" => cardButton.internalLink,
    cardButton.type == "external-link" => cardButton.externalLink,
    cardButton.type == "scroll-to-link" => cardButton.scrollToLink,
    ""
  ),
    },
    cardColor,
    cardDescription,
    "cardImage": {
      ...cardImage,
      "asset": cardImage.asset->
    }
  },
  featuredQuotes[]->
}`;
const HomeSections = ({
  homeData
}: {
  homeData: SanityHomepage;
}) => {
  console.log("HOME DATA", homeData);
  return _jsxs(_Fragment, {
    children: [_jsx(Header, {}), _jsx(Hero, {
      title: homeData.heroTitle,
      subtitle: homeData.heroSubtitle
    }), _jsx(PlatformSection, {
      body: homeData.platformBody,
      button: homeData.platformButton
    }), _jsx(UseCaseSection, {
      useCases: homeData.featuredUseCases
    }), _jsx(AboutUs, {
      subtitle: homeData.aboutUsSubtitle,
      body: homeData.aboutUsBody,
      button: homeData.aboutUsButton
    }), _jsx(QuoteSection, {
      quotes: homeData.featuredQuotes
    }), _jsx(Footer, {})]
  });
};
var _ref = process.env.NODE_ENV === "production" ? {
  name: "em0qpq",
  styles: "display:grid;min-height:100vh;place-items:center;font-size:108px;line-height:102.6px;--tw-text-opacity:1;color:rgb(255 72 37 / var(--tw-text-opacity))"
} : {
  name: "11o3728-Home",
  styles: "display:grid;min-height:100vh;place-items:center;font-size:108px;line-height:102.6px;--tw-text-opacity:1;color:rgb(255 72 37 / var(--tw-text-opacity));label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "vuursz",
  styles: "display:grid;min-height:100vh;place-items:center;padding-left:2rem;padding-right:2rem;font-size:20px;line-height:35px;font-weight:700"
} : {
  name: "13c79zv-Home",
  styles: "display:grid;min-height:100vh;place-items:center;padding-left:2rem;padding-right:2rem;font-size:20px;line-height:35px;font-weight:700;label:Home;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default function Home({
  data,
  preview
}: InferGetStaticPropsType<typeof getStaticProps>) {
  const homeData = data && data[0];
  return _jsxs(_Fragment, {
    children: [_jsxs(Head, {
      children: [_jsx("title", {
        children: `Fireworx AI ${homeData ? `| ${homeData.seo.title}` : ""}`
      }), _jsx("meta", {
        name: "description",
        content: homeData?.seo?.description ?? "Revolutionizing Problem-Solving with AI."
      }), _jsx("meta", {
        name: "viewport",
        content: "width=device-width, initial-scale=1"
      }), _jsx("link", {
        rel: "icon",
        href: "/fireworx-favicon.png"
      }), _jsx("meta", {
        property: "og:title",
        content: `Fireworx AI ${homeData ? `| ${homeData.seo.title}` : ""}`
      }), _jsx("meta", {
        property: "og:type",
        content: "website"
      }), _jsx("meta", {
        property: "og:url",
        content: "https://www.fireworx.ai/"
      }), _jsx("meta", {
        property: "og:image",
        content: "/og-image.png"
      })]
    }), homeData ? _jsx(HomeSections, {
      homeData: homeData
    }) : preview ? _jsx(PreviewSuspense, {
      fallback: _jsx("div", {
        css: _ref,
        children: _jsx(Ellipsis, {})
      }),
      children: _jsx(PreviewHome, {})
    }) : _jsx("div", {
      css: _ref2,
      children: "The page is not in preview mode but it's data is not defined"
    })]
  });
}
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "n4nmn4",
  styles: "position:fixed;bottom:0px;right:0px;--tw-bg-opacity:1;background-color:rgb(255 72 37 / var(--tw-bg-opacity));padding:1.5rem;font-weight:700;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity))"
} : {
  name: "fwtg0j-PreviewHome",
  styles: "position:fixed;bottom:0px;right:0px;--tw-bg-opacity:1;background-color:rgb(255 72 37 / var(--tw-bg-opacity));padding:1.5rem;font-weight:700;--tw-text-opacity:1;color:rgb(0 0 0 / var(--tw-text-opacity));label:PreviewHome;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const PreviewHome = () => {
  const data = usePreview(null, query);
  const homeData: SanityHomepage = data && data[0];
  return _jsxs(_Fragment, {
    children: [_jsx(HomeSections, {
      homeData: homeData
    }), _jsx(Link, {
      css: _ref3,
      href: "/api/exit-preview",
      children: "Exit Preview"
    })]
  });
};
export const getStaticProps = async ({
  preview = false
}: GetStaticPropsContext) => {
  if (preview) {
    return {
      props: {
        preview
      }
    };
  }
  const data: SanityDocument<SanityHomepage>[] = await client.fetch(query);
  return {
    props: {
      preview,
      data
    }
  };
};